body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.GridLoader {
  min-height: 50vh;
  display: flex;
  align-items: center;
}

.left-col {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
}

.center-col {
  flex: 1 1;
  background: #aaa;
  overflow-y: scroll;
}

.right-col {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
}

.img-fluid {
  max-width: 50% !important;
}

.sidebar .user .info span,
.sidebar[data-background-color="white"] .user .info span {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  display: flex;
  flex-direction: column;
}

#view2 {
    height: 300px;
    /* background-color: yellowgreen; */
}
.pane {
    background: #fff;
    border-style: solid;
    border-color: #fff;
    border-width: 1px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    /* box-shadow: 2px 2px 2px #dddddd; */
}

.header {
    background-color: #eee;
    margin: 5px;
    padding-left: 10px;
    padding-top: 4px;
    border-radius: 5px;
    height: 30px;
}










#view2 {
    height: 300px;
    /* background-color: yellowgreen; */
}
